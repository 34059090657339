export default
	props:
		title: type: String, default: 'No Title'
		subtitle: type: String, default: 'No subtitle'
		leftButtonText: type: String, default: 'OK'
		rightButtonText: type: String, default: 'Cancel'
		callback: type: Function
		callbackRightButton: type: Function
	methods:
		leftButtonClick: ->
			@callback?()
		rightButtonClick: ->
			@callbackRightButton?()
